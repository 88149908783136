.containerBrosur {
  width: 100%;
  height: 600px;
  overflow: hidden;
  border-radius: 20px;
}

.brosur {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
