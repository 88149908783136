.coverContainer {
  width: 100%;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
}

.coverImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.containerSilabus {
  background-color: #ffffff;
  width: 100%;
  height: 500px;
  border-radius: 20px;
}

@media only screen and (max-width: 770px) {
  .coverContainer {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .coverImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .containerSilabus {
    background-color: #ffffff;
    width: 100%;
    height: 500px;
    border-radius: 20px;
  }
}

.containerJudul {
  height: 60px;
  background-color: #f0f0f0;
  border-radius: 25px;
  cursor: pointer;
}

.buttonKelasPrev {
  width: 100%;
  height: 40px;
  border-radius: 20px;
}
