.carousel {
  width: 100%;
  height: 80vh;
}

.carousel-item {
  height: 80vh;
  width: 100%;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  object-fit: cover;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
