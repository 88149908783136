.logoContainer {
  width: 100%;
  height: 250px;
}

.logoImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.strOrgContainer {
  width: 100%;
  height: 350px;
  border-radius: 30px;
  overflow: hidden;
}

.strOrgImage {
  width: 70%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}
