.containerImgMading {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 20px;
}

.imgMading {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imgMading2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgMadingContainer {
  width: 100%;
  height: 100px;
  overflow: hidden;
  border-radius: 20px;
}
