.misi {
  list-style-type: none;
}
.LogoImage {
  width: 380px;
  height: 500px;
}

.timContainer {
  width: 300px;
  height: 300px;
  border-radius: 50px;
  overflow: hidden;
}

.timImage {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .LogoImage {
    display: block;
    margin: auto;
    width: 200px;
    height: 300px;
  }

  .timContainer {
    width: 100%;
    height: 300px;
    border-radius: 50px;
    overflow: hidden;
  }

  .timImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
  }
}
